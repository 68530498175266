<template>
  <img :src="image" @error="onImageError" />
</template>

<script>
export default {
  name: "ZImage",

  props: {
    src: {
      type: String,
      default: ""
    },
    fallback: {
      type: String,
      default: "img/play.png"
    }
  },

  data() {
    return {
      found: true
    };
  },

  computed: {
    srcImage() {
      if (this.isURL(this.src)) return this.src;
      return this.getImage(this.src);
    },
    fallbackImage() {
      if (this.isURL(this.fallback)) return this.fallback;
      return this.getImage(this.fallback);
    },
    image() {
      if (this.found) return this.srcImage;
      return this.fallbackImage;
    }
  },

  watch: {
    src: {
      immediate: true,
      handler() {
        this.found = true;
      }
    }
  },

  methods: {
    onImageError() {
      this.found = false;
      this.$emit("image-error");
    },

    isURL(s) {
      try {
        const url = new URL(s);
        return url.protocol === "http:" || url.protocol === "https:";
      } catch (_) {
        return false;
      }
    },

    getImage(s) {
      try {
        return require(`@/assets/${s}`);
      } catch (e) {
        return "";
      }
    }
  }
};
</script>
