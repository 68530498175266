<template>
  <div
    class="z-profile-image"
    :class="{ sm: size === 'sm', lg: size === 'lg' }"
  >
    <z-image
      v-if="image && !imageError"
      :src="image"
      class="image"
      @image-error="imageError = true"
    />
    <font-awesome-icon
      v-else
      :icon="icon"
      :flip="icon === 'helmet' ? 'horizontal' : undefined"
      :class="type"
    />
  </div>
</template>

<script>
import ZImage from "@/app/components/ZImage";
import BusinessLine from "@/constants/clients/businessLine";

const types = {
  USER: "user",
  DRIVER: "driver",
  WAREHOUSE: "warehouse"
};

export default {
  name: "ZProfileImage",

  components: {
    ZImage
  },

  props: {
    size: {
      type: String,
      default: "sm",
      validator: val => ["sm", "lg"].indexOf(val) > -1
    },
    type: {
      type: String,
      default: "user",
      validator: val =>
        [types.USER, types.DRIVER, types.WAREHOUSE].indexOf(val) > -1
    },
    businessLine: {
      type: Number,
      default: -1
    },
    image: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      imageError: false
    };
  },

  computed: {
    icon() {
      if (this.type === types.DRIVER) {
        return "helmet";
      } else if (this.type === types.WAREHOUSE) {
        return "warehouse";
      } else {
        switch (this.businessLine) {
          case BusinessLine.NUM_STORE:
            return "business";
          case BusinessLine.NUM_RESTAURANT:
            return "store-alt";
          default:
            return "user";
        }
      }
    },
    showImage() {
      return this.image && !this.imageError;
    }
  }
};
</script>

<style lang="scss" scoped>
$border-radius: 8px;

.z-profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: darken($white-smoke, 5%);
  border-radius: $border-radius;

  .image {
    height: 100%;
    width: 100%;
    border-radius: $border-radius;
    object-fit: cover;
  }

  svg.warehouse {
    transform: translate(-2px, -2px);
  }

  &.sm {
    width: 40px;
    height: 40px;
    min-width: 40px;
    font-size: 1.8rem;
  }

  &.lg {
    width: 100px;
    height: 100px;
    min-width: 100px;
    font-size: 4rem;
  }
}
</style>
